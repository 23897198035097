import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";

@Injectable()
export class ThemeService {
  public static default = 'default';
  public currentTheme?: string;
  private readonly style?: HTMLLinkElement;
  private isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      this.style = document.getElementById('theme-style') as HTMLLinkElement;
      this.current = ThemeService.default;
    }
  }

  get current(): string {
    return this.currentTheme ? this.currentTheme : ThemeService.default;
  }

  set current(value: string) {
    this.currentTheme = value;
    if (this.style) {
      // this.style.href = `/assets/scss/theme/${value}.scss`;
      document.body.className = `theme-${value}`;
    }

  }
}
